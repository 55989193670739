import {
  computed,
  ref,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { useUtils as useI18nUtils } from '@core/libs/i18n';
import cashbookStoreModule from '../cashbookStoreModule';

export default function useDeleteCashbookModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'cashbook';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, cashbookStoreModule);
  }

  const toastification = toast();
  const isSubmitting = ref(false);
  const refModalDeleteCashbook = ref(null);
  const refForm = ref(refFormObserver);
  const blankItem = {
    items: JSON.parse(JSON.stringify(props.items)),
    cashbook: null,
  };
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
    isSubmitting.value = false;
  };
  const { t } = useI18nUtils();
  const resetModal = () => {
    resetItemLocal();
  };

  const cashbooks = computed(() => JSON.parse(JSON.stringify(props.items)));

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success || cashbooks.value.length === 0) {
      return;
    }

    const data = {
      ids: cashbooks.value.map(obj => obj.id),
      newId: itemLocal.value.cashbook.id,
    };

    isSubmitting.value = true;

    store
      .dispatch('cashbook/deleteCashbooks', data)
      .then(response => { emit('on-item-updated', response.data); })
      .then(() => {
        refModalDeleteCashbook.value.toggle('#toggle-btn');
      })
      .then(() => {
        emit('refetch-data');
      })
      .then(() => {
        toastification.showToastUpdateSuccess();
        isSubmitting.value = false;
      })
      .catch(error => {
        toastification.showToastError(error, refForm.value);
        isSubmitting.value = false;
      });
  };

  return {
    refModalDeleteCashbook,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
    cashbooks,
    isSubmitting,
    t,
  };
}
